@import '~antd/dist/antd.css';

.Main {
  text-align: center;
}

h1 {
  font-family: ltc-bodoni-175;
  font-size: 64px;
  letter-spacing: .04em;
  font-weight: 400;
  margin-bottom: 0;
  text-transform: uppercase;
}

.sub {
  font-size: 16px;
  text-align: center;
  font-family: brandon-grotesque;
  line-height: 1em;
  letter-spacing: .3em;
  font-weight: 500;
  font-style: normal;
}
.icons {
  display: flex;
  margin: 20px auto;
  width: 300px;
  justify-content: space-evenly;
}

.anticon {
  font-size: 40px;
}
